import { useMemo } from 'react';
import { object, string } from 'yup';
import { CustomerType } from '../enums/customer-type.enum';
import { useTranslation } from 'react-i18next';

export function useCustomerSchema() {
    const { t } = useTranslation();
    return useMemo(
        () =>
            object().shape({
                name: string().required(),
                type: string().oneOf(Object.values(CustomerType), t('requiredField')).required(),
            }),
        [t],
    );
}
