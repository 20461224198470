import { ControlledSelect, Options, OptionsToggle } from '../../../shared';
import { ICustomerQuery } from '../../models/customer.model';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { checkDifferentFilterAndFormValues } from '../../../shared/utils/check-different-filter-and-form-values.util';
import { MenuItem, Stack } from '@mui/material';
import { CustomerType } from '../../enums/customer-type.enum';

interface Props {
    filter: Record<string, any>;
    onChange: (filter: ICustomerQuery & { options: Options }) => void;
}

export const CustomerFilter: FC<Props> = ({ filter, onChange }) => {
    const { t } = useTranslation();
    const form = useForm<ICustomerQuery>({ mode: 'onChange' });

    const defaultOptions: Options = useMemo(() => {
        return {
            filter: { type: 'title', label: t('filter') },
            types: { label: t('customerTypes'), active: false },
        };
    }, [t]);

    const filterOptions: Options = useMemo(() => filter.options || defaultOptions, [defaultOptions, filter.options]);

    const setOptions = useCallback((options: Options) => onChange({ ...filter, options }), [filter, onChange]);

    const formValues = form.watch();

    useEffect(() => form.reset(filter), [filter, form]);

    useEffect(() => {
        if (!filterOptions.types.active) form.setValue('types', undefined);
    }, [form, filterOptions]);

    useEffect(() => {
        if (!!Object.keys(formValues).length && checkDifferentFilterAndFormValues(formValues, filter)) {
            onChange({ ...formValues, options: filterOptions });
        }
    }, [formValues, filter, onChange, filterOptions]);

    return (
        <FormProvider {...form}>
            <Stack direction="row" alignItems="center" spacing={2}>
                <OptionsToggle options={filterOptions} onChange={setOptions} />
                {filterOptions.types.active && (
                    <ControlledSelect name="types" className="filter" label={t('customerTypes')} multiple>
                        <MenuItem value={CustomerType.SOLIDARITY} key={CustomerType.SOLIDARITY}>
                            {t(CustomerType.SOLIDARITY)}
                        </MenuItem>
                        <MenuItem value={CustomerType.PROJECT} key={CustomerType.PROJECT}>
                            {t(CustomerType.PROJECT)}
                        </MenuItem>
                    </ControlledSelect>
                )}
            </Stack>
        </FormProvider>
    );
};
