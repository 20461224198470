import { yupResolver } from '@hookform/resolvers/yup';
import { Button, MenuItem, Stack } from '@mui/material';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ProductTaxes } from '../../../admin/enums';
import { useSettings } from '../../../admin/hooks';
import {
    ControlledCheckbox,
    ControlledInput,
    ControlledNumberInput,
    ControlledSelect,
    FormGrid,
    IManualSaleItemForm,
    useSelectedOrganisation,
} from '../../../shared';
import { useCreateManualSaleItem } from '../../hooks';
import { validateCurrentAmount } from '../../utils';
import { usePriceProduct } from '../../validators';
import { InputNumpad } from '../input-numpad/input-numpad.component';
import { ISupportCaseInfo } from '../../../customer/models/support-case.model';

interface Props {
    supportCase?: ISupportCaseInfo;
}

export const PriceProduct: FC<Props> = ({ supportCase }) => {
    const { t } = useTranslation();
    const { organisation } = useSelectedOrganisation();
    const { data: settings } = useSettings(organisation?.id);

    const { mutateAsync: createManualSaleItem } = useCreateManualSaleItem();
    const [selectedField, setSelectedField] = useState<string>('productPrice');
    const [productPrice, setProductPrice] = useState<string>('');
    const [amount, setAmount] = useState<string>('1');

    const form = useForm<IManualSaleItemForm>({
        resolver: yupResolver(usePriceProduct()),
    });

    const onClose = useCallback(() => {
        form.reset({ tax: 0, excludeFromBudget: false });
        setProductPrice('');
        setAmount('1');
    }, [form]);

    useEffect(() => {
        onClose();
    }, [form, onClose]);

    const onNumpadInput = useCallback(
        (input: string) => {
            if (selectedField === 'productPrice') return setProductPrice(input);
            if (selectedField === 'amount') return setAmount(input);
        },
        [selectedField],
    );

    const disableInputOptions = useMemo(() => {
        return selectedField === 'productPrice'
            ? validateCurrentAmount(productPrice, undefined, true)
            : validateCurrentAmount(amount);
    }, [amount, productPrice, selectedField]);

    const setFormValuesOnSubmit = () => {
        form.setValue('productPrice', parseFloat(productPrice), { shouldValidate: true });
        form.setValue('amount', parseFloat(amount), { shouldValidate: true });
    };

    const onSubmit = async (item: IManualSaleItemForm) => {
        if (organisation) {
            await createManualSaleItem({
                organisationId: organisation.id,
                supportCaseId: supportCase?.supportCaseId || undefined,
                cardCode: supportCase?.cardCode || undefined,
                amountOfPeople: supportCase?.supportees.length || undefined,
                ...item,
                productName: item.productName ? item.productName : t('generalSale'),
                productPrice: item.productPrice,
                amount: item.amount,
                excludeFromBudget: item.excludeFromBudget,
            });
            onClose();
        }
    };

    return (
        <>
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <FormGrid sm={12} md={6} justifyContent="center">
                        <ControlledInput name="productName" label={t('name')} placeholder={t('generalSale')} />
                        <ControlledNumberInput
                            name="productPrice"
                            value={productPrice}
                            label={t('productPrice')}
                            required
                            disabled={disableInputOptions.disableButtons}
                            onSelect={() => setSelectedField('productPrice')}
                            onChange={(input) => setProductPrice(input.target.value)}
                            slotProps={{ htmlInput: { readOnly: true } }}
                        />
                        <ControlledNumberInput
                            name="amount"
                            value={amount}
                            label={t('amount')}
                            required
                            onSelect={() => setSelectedField('amount')}
                            onChange={(input) => setAmount(input.target.value)}
                            slotProps={{ htmlInput: { readOnly: true } }}
                        />
                        <ControlledSelect name="tax" label={t('productTax')} required>
                            {Object.values(ProductTaxes)?.map((value) => (
                                <MenuItem value={parseInt(value)} key={value}>
                                    {value}
                                </MenuItem>
                            ))}
                        </ControlledSelect>
                        {supportCase && (
                            <ControlledCheckbox
                                control={form.control}
                                name="excludeFromBudget"
                                label={t('excludeFromBudget')}
                            />
                        )}
                    </FormGrid>

                    <Stack direction="column" spacing={2} alignItems="center">
                        <InputNumpad
                            value={selectedField === 'amount' ? amount : productPrice}
                            onChange={onNumpadInput}
                            disable={disableInputOptions}
                            showNumpad={settings?.showNumpad}
                        />
                        <Button
                            sx={{ width: '100%' }}
                            variant="contained"
                            onClick={setFormValuesOnSubmit}
                            type="submit"
                        >
                            {t('addAmount')}
                        </Button>
                    </Stack>
                </form>
            </FormProvider>
        </>
    );
};
