import { Button, IconButton, Tooltip, Typography } from '@mui/material';
import React, { FC, MouseEventHandler, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined } from '@mui/icons-material';

interface Props {
    cardId: string;
    variant?: 'icon' | 'default';
}

export const DownloadCard: FC<Props> = ({ cardId, variant }) => {
    const { t } = useTranslation();

    const downloadCard: MouseEventHandler<HTMLButtonElement> = useCallback(
        (e) => {
            window.open(`/shop-api/cards/${cardId}`, '__blank');
            e.stopPropagation();
        },
        [cardId],
    );

    return variant === 'icon' ? (
        <Tooltip title={<Typography sx={{ fontSize: '13px' }}>{t('downloadCard')}</Typography>} arrow>
            <IconButton onClick={downloadCard}>
                <DownloadOutlined />
            </IconButton>
        </Tooltip>
    ) : (
        <Button color="secondary" variant="outlined" sx={{ height: '35px' }} onClick={downloadCard}>
            {t('downloadCard')}
        </Button>
    );
};
