import { Button } from '@mui/material';
import { endOfMonth, startOfMonth } from 'date-fns';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Page } from '../../../shared';
import { Report, ReportFilter } from '../../components';
import { useReport, useReportExport } from '../../hooks';
import {
    FilterType,
    IReportFilter,
    IRunReportDateFilter,
    IRunReportFilter,
    IRunReportListFilter,
    IRunReportNumberFilter,
    IRunReportStringFilter,
} from '../../models';

export const ReportPage: FC = () => {
    const { t } = useTranslation();
    const { id = '' } = useParams();
    const { data: report, isPending: isReportLoading } = useReport(id);
    const { mutate: exportReport } = useReportExport();
    const availableFilters = useMemo(() => report?.filters ?? [], [report]);
    const hasFilters = useMemo(() => availableFilters.length > 0, [availableFilters]);
    const hasRequiredFilters = useMemo(() => availableFilters.some(({ required }) => required), [availableFilters]);
    const defaultFilters = useDefaultFilters(availableFilters);
    const [filters, setFilters] = useState<IRunReportFilter[]>([]);
    const isPageLoading = isReportLoading || (hasRequiredFilters && !filters.length);

    useEffect(() => {
        setFilters(defaultFilters);
    }, [defaultFilters]);

    const onExport = useCallback(() => {
        exportReport({ id, filters: filters, name: report?.name || '' });
    }, [exportReport, id, filters, report]);

    return (
        <Page
            title={report?.name}
            loading={isPageLoading}
            filter={
                hasFilters && (
                    <ReportFilter filters={filters} availableFilters={availableFilters} onChange={setFilters} />
                )
            }
            actions={
                <Button variant="contained" onClick={onExport}>
                    {t('export')}
                </Button>
            }
        >
            {report && <Report report={report} filters={filters} />}
        </Page>
    );
};

function useDefaultFilters(filters: IReportFilter[]): IRunReportFilter[] {
    return useMemo(
        () =>
            filters
                .filter(({ required }) => required)
                .map((filter) => {
                    switch (filter.type) {
                        case FilterType.DATE:
                            return getDefaultDateFilter(filter);
                        case FilterType.SELECT:
                            return getDefaultSelectFilter(filter);
                        case FilterType.SINGLE_SELECT:
                            return getDefaultSingleSelectFilter(filter);
                        case FilterType.NUMBER:
                            return getDefaultNumberFilter(filter);
                    }
                }),
        [filters],
    );
}

function getDefaultNumberFilter(filter: IReportFilter): IRunReportNumberFilter {
    return {
        type: FilterType.NUMBER,
        column: filter.column,
        min: 0,
        max: 100,
    };
}

function getDefaultDateFilter(filter: IReportFilter): IRunReportDateFilter {
    return {
        type: FilterType.DATE,
        column: filter.column,
        start: startOfMonth(new Date()).toISOString(),
        end: endOfMonth(new Date()).toISOString(),
    };
}

function getDefaultSelectFilter(filter: IReportFilter): IRunReportListFilter {
    return {
        type: FilterType.SELECT,
        column: filter.column,
        values: filter.values?.map((v) => v.value) ?? [],
    };
}

function getDefaultSingleSelectFilter(filter: IReportFilter): IRunReportStringFilter {
    return {
        type: FilterType.SINGLE_SELECT,
        column: filter.column,
        value: filter.values?.[0]?.value || '',
    };
}
