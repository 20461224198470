import { Stack, Typography } from '@mui/material';
import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateString, formatPrice, IOrganisation, LabelValue, useBreakpoint } from '../../../../shared';
import { ISupportPeriod } from '../../../models/support-period.model';

interface Props {
    activeSupportPeriod?: ISupportPeriod;
    organisation?: IOrganisation;
}

export const SupportPeriodsInfo: FC<Props> = ({ activeSupportPeriod, organisation }) => {
    const { t } = useTranslation();
    const lg = useBreakpoint('lg');

    return (
        <LabelValue
            label={t('supportPeriodUntil')}
            value={
                <Stack
                    spacing={1}
                    direction={lg ? 'column' : 'row'}
                    columnGap={2}
                    color={activeSupportPeriod?.organisationId === organisation?.id ? 'inherit' : 'warning.main'}
                >
                    <Typography variant="caption">
                        {activeSupportPeriod?.endDate ? formatDateString(activeSupportPeriod?.endDate) : '-'}
                    </Typography>

                    {activeSupportPeriod?.supportTypesInfo?.map(({ supportType, credit = 0, spendCredit = 0 }) => (
                        <Fragment key={supportType?.id}>
                            <Typography variant="caption">
                                {activeSupportPeriod?.organisationId &&
                                    activeSupportPeriod?.organisationId !== organisation?.id &&
                                    activeSupportPeriod?.organisation?.name}

                                <Stack
                                    direction={lg ? 'column' : 'row'}
                                    spacing={lg ? 0 : 1}
                                    sx={{ span: { display: 'block' } }}
                                >
                                    <span>{supportType?.name}</span>
                                    {!lg && <span>{' - '}</span>}
                                    <span>
                                        {supportType?.hasCredit &&
                                            `${t('credit')}:  ${formatPrice(credit - spendCredit)}`}
                                    </span>
                                </Stack>
                            </Typography>
                        </Fragment>
                    ))}
                </Stack>
            }
        />
    );
};
