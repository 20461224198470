import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, Typography } from '@mui/material';
import { AxiosError, isAxiosError } from 'axios';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSettings } from '../../../../admin/hooks';
import {
    BarcodeWarning,
    DialogTitleWithClose,
    FormGrid,
    MeasurementUnit,
    SaveProductInputs,
    Section,
    StatusCode,
    useSelectedOrganisation,
} from '../../../../shared';
import { useCreatePurchaseItem } from '../../../hooks';
import { purchaseNewProductItemFromFormMapper } from '../../../mappers';
import { INewProductPurchaseItemForm, IPurchase } from '../../../models';
import { useNewProductPurchaseItemSchema } from '../../../validators';
import { PurchaseItemInputs } from '../purchase-item-form/purchase-item-inputs/purchase-item-inputs.component';

interface Props {
    onClose: () => void;
    setShowNewProduct: (showNewProduct: boolean) => void;
    purchase: IPurchase;
}

export const PurchaseItemNewProductForm: FC<Props> = ({ onClose, setShowNewProduct, purchase }) => {
    const { t } = useTranslation();
    const { organisation } = useSelectedOrganisation();
    const [showWarning, setShowWarning] = useState(false);

    const { data: settings } = useSettings(organisation?.id);
    const { mutateAsync: createPurchaseItem, isPending: isSaving } = useCreatePurchaseItem();

    const form = useForm<INewProductPurchaseItemForm>({
        mode: 'onSubmit',
        resolver: yupResolver(useNewProductPurchaseItemSchema()),
    });
    const watchPurchasePrice = form.watch('unitPurchasePrice');
    const watchTaxIncluded = form.watch('taxIncluded');
    const watchTax = form.watch('newProduct.tax');

    useEffect(() => {
        form.reset({ taxIncluded: true, newProduct: { hasPrice: true, measurementUnit: MeasurementUnit.PIECE } });
    }, [form]);

    useEffect(() => {
        if (watchPurchasePrice) {
            const tax = watchTax ? Number(watchTax) / 100 : 0;
            const price = watchTaxIncluded && watchTax ? watchPurchasePrice : watchPurchasePrice * (1 + tax);
            form.setValue('newProduct.price', ((100 - (purchase.supplier?.discountPercentage || 0)) * price) / 100);
        }
    }, [form, purchase, watchPurchasePrice, watchTaxIncluded, watchTax]);

    useEffect(() => {
        if (settings) {
            const tax = watchTax ? Number(watchTax) / 100 : 0;
            const price = watchTaxIncluded ? watchPurchasePrice : watchPurchasePrice * (1 + tax);
            const value = settings?.solidarityPriceIsPurchasePrice
                ? price
                : (price || 0) * (1 + (settings?.additionalSolidarityCharge || 0) / 100);

            form.setValue('newProduct.solidarityPrice', value);
        }
    }, [form, settings, watchPurchasePrice, watchTaxIncluded, watchTax]);

    const handleSubmit = useCallback(
        async (item: INewProductPurchaseItemForm, ignoreWarning = false) => {
            if (ignoreWarning || item.newProduct.barcodes?.length > 0) {
                try {
                    if (organisation?.id) {
                        await createPurchaseItem(purchaseNewProductItemFromFormMapper(purchase, item));
                        onClose();
                    }
                } catch (e) {
                    if (isAxiosError(e)) {
                        if ((e as AxiosError)?.response?.status === StatusCode.CONFLICT) {
                            form.setError('newProduct.barcodes.0', {
                                type: 'custom',
                                message: t('barcodesAlreadyExists'),
                            });
                        } else {
                            throw e;
                        }
                    } else {
                        throw e;
                    }
                }
            } else {
                setShowWarning(true);
            }
        },
        [form, organisation, createPurchaseItem, t, purchase, onClose],
    );

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit((item) => handleSubmit(item))} noValidate>
                <DialogTitleWithClose onClose={onClose} children={t('createNewProduct')} />
                <DialogContent>
                    <SaveProductInputs
                        prefixName={'newProduct.'}
                        slot={
                            <Section title={t('purchaseInfo')}>
                                <PurchaseItemInputs />
                            </Section>
                        }
                    />
                    {Object.keys(form.formState.errors).length > 0 && (
                        <Typography color="error">{t('errorIncorrectValues')}</Typography>
                    )}
                    <BarcodeWarning
                        showWarning={showWarning}
                        setShowWarning={setShowWarning}
                        onSubmit={() => {
                            setShowWarning(false);
                            form.handleSubmit((item) => handleSubmit(item, true))();
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <FormGrid xs={12} sm={6} justifyContent="space-between" sx={{ button: { width: '100%' } }}>
                        <Button variant="outlined" disabled={isSaving} onClick={() => setShowNewProduct(false)}>
                            {t('shared.previous')}
                        </Button>
                        <Button variant="contained" type="submit" disabled={isSaving} sx={{ minWidth: '100%' }}>
                            {t('createProduct')}
                        </Button>
                    </FormGrid>
                </DialogActions>
            </form>
        </FormProvider>
    );
};
