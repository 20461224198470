import { Grid } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ISale, LabelValue, useBreakpoint, useSelectedOrganisation, Widget } from '../../../shared';
import { SupportCaseInfoWarningPanel } from './support-case-info-warning-panel/support-case-info-warning-panel.component';
import { SupportCaseInfoData } from './support-case-info-data/support-case-info-data.component';
import { SupportPeriodsInfo } from './support-periods-info/support-periods-info.component';
import { ISupportCaseInfo } from '../../models/support-case.model';

interface Props {
    supportCase?: ISupportCaseInfo;
    sale?: ISale;
}

export const SupportCaseInfoComponent: FC<Props> = ({ supportCase, sale }) => {
    const { t } = useTranslation();
    const lg = useBreakpoint('lg');
    const showWarningPanel = window.carity.environment.showWarningPanel;
    const { organisation } = useSelectedOrganisation();

    const activeSupportPeriod = useMemo(
        () =>
            supportCase?.activeSupportPeriods
                .filter((period) =>
                    period.supportTypesInfo.some(
                        (type) => type.supportTypeId === window.carity.environment.supportTypeId,
                    ),
                )
                .sort((a, b) => b.endDate.localeCompare(a.endDate))[0],
        [supportCase],
    );

    return (
        <>
            {!supportCase ? (
                <Widget sx={{ minHeight: 'fit-content', mb: 0 }}>
                    <LabelValue label={t('customer')} value={t('solidarityCustomer')} />
                </Widget>
            ) : (
                <Grid container spacing={2} flexDirection={lg ? 'row' : 'column-reverse'}>
                    <Grid
                        item
                        md={12}
                        lg={activeSupportPeriod?.endDate || showWarningPanel ? (showWarningPanel ? 8 : 9) : 12}
                    >
                        <SupportCaseInfoData
                            supportCase={supportCase}
                            sale={sale}
                            activeSupportPeriod={activeSupportPeriod}
                        />
                    </Grid>

                    {showWarningPanel ? (
                        <Grid item md={12} lg={4}>
                            <SupportCaseInfoWarningPanel
                                supportCase={supportCase}
                                activeSupportPeriod={activeSupportPeriod}
                            />
                        </Grid>
                    ) : (
                        <Grid item md={12} lg={3}>
                            {activeSupportPeriod?.endDate && (
                                <Widget sx={{ minHeight: 'fit-content', mb: 0 }}>
                                    <SupportPeriodsInfo
                                        activeSupportPeriod={activeSupportPeriod}
                                        organisation={organisation}
                                    />
                                </Widget>
                            )}
                        </Grid>
                    )}
                </Grid>
            )}
        </>
    );
};
