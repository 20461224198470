import { Close } from '@mui/icons-material';
import { Alert, AlertColor, AlertTitle, IconButton, Snackbar } from '@mui/material';
import axios, { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { authApi, shopApi, supportApi } from '../../../shared';

export function Statusbar() {
    const { t } = useTranslation();
    const [status, setStatus] = useState<{ show: boolean; message: string; type: AlertColor | undefined }>({
        show: false,
        message: '',
        type: undefined,
    });

    const onSuccess = (response: AxiosResponse) => {
        if (response.config.successMessage) {
            setStatus({ show: true, message: response.config.successMessage, type: 'success' });
        }
        return response;
    };

    useEffect(() => {
        const onError = (error: any) => {
            if (error.response?.status === 401) {
                window.location.href = '/auth/logout';
            }
            if (error.response.status >= 500) {
                if (error.config.errorMessage) {
                    setStatus({ show: true, message: error.config.errorMessage, type: 'error' });
                } else {
                    setStatus({ show: true, message: t('shared.tryAgainLater'), type: 'error' });
                }
            }
            return Promise.reject(error);
        };

        axios.interceptors.response.use(onSuccess, onError);
        supportApi.interceptors.response.use(onSuccess, onError);
        authApi.interceptors.response.use(onSuccess, onError);
        shopApi.interceptors.response.use(onSuccess, onError);
    }, [t]);

    const onClose = () => {
        setStatus({ ...status, show: false });
    };

    return (
        <Snackbar open={status.show} autoHideDuration={12000} onClose={onClose}>
            <Alert
                severity={status.type}
                action={
                    <IconButton size="small" aria-label="close" color="inherit" onClick={onClose}>
                        <Close fontSize="small" />
                    </IconButton>
                }
            >
                {status.type == 'error' && <AlertTitle>{t('shared.somethingWentWrong')}</AlertTitle>}
                {status.message}
            </Alert>
        </Snackbar>
    );
}
