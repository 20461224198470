import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, CardActions, CardContent, Typography } from '@mui/material';
import { AxiosError, isAxiosError } from 'axios';
import React, { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
    BarcodeWarning,
    IParams,
    IProductForm,
    MeasurementUnit,
    Page,
    productFromFormMapper,
    productToFormMapper,
    RemoveModal,
    SaveProductInputs,
    StatusCode,
    useDeleteProduct,
    useProduct,
    useSaveProduct,
    useSelectedOrganisation,
} from '../../../shared';
import { useSettings } from '../../hooks';
import { useProductSchema } from '../../validators';

export const ProductEditPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams<keyof IParams>() as IParams;
    const { organisation } = useSelectedOrganisation();
    const [showWarning, setShowWarning] = useState(false);

    const { data: settings } = useSettings(organisation?.id);
    const { data: product, isFetching } = useProduct(id);
    const { mutateAsync: saveProduct, isPending: isSaving } = useSaveProduct();
    const { mutateAsync: deleteProduct } = useDeleteProduct();

    const form = useForm<IProductForm>({
        mode: 'onSubmit',
        resolver: yupResolver(useProductSchema()),
    });
    const barcodesWatch = form.watch('barcodes');
    const watchPrice = form.watch('price');

    useEffect(() => {
        if (product) {
            form.reset(productToFormMapper(product));
        } else {
            form.reset({ hasPrice: true, measurementUnit: MeasurementUnit.PIECE });
        }
    }, [product, form]);

    useEffect(() => {
        if (!id && watchPrice) {
            if (settings && !settings?.solidarityPriceIsPurchasePrice) {
                form.setValue('solidarityPrice', watchPrice * (1 + (settings?.additionalSolidarityCharge || 0) / 100));
            } else if (settings) {
                form.setValue('solidarityPrice', watchPrice);
            }
        }
    }, [form, settings, watchPrice, id]);

    const onSubmit = useCallback(
        async (item: IProductForm, ignoreWarning = false) => {
            if (ignoreWarning || barcodesWatch?.length > 0) {
                try {
                    if (organisation?.id) {
                        const result = await saveProduct({
                            id,
                            item: productFromFormMapper(item, organisation.id),
                        });
                        navigate(`/admin/products/${result.id}`);
                    }
                } catch (e) {
                    if (isAxiosError(e)) {
                        if ((e as AxiosError)?.response?.status === StatusCode.CONFLICT) {
                            form.setError('barcodes.0', {
                                type: 'custom',
                                message: t('barcodesAlreadyExists'),
                            });
                        } else {
                            throw e;
                        }
                    } else {
                        throw e;
                    }
                }
            } else {
                setShowWarning(true);
            }
        },
        [barcodesWatch, form, id, navigate, organisation, saveProduct, t],
    );

    const onDelete = useCallback(async () => {
        await deleteProduct(id);
        navigate('/admin/products');
    }, [deleteProduct, navigate, id]);

    const actions = useMemo(
        () => [
            <Button
                key={'submit'}
                variant="contained"
                color="primary"
                onClick={form.handleSubmit((item) => onSubmit(item))}
                disabled={isFetching}
            >
                {t('save')}
            </Button>,
            <Fragment key="delete">
                {id && (
                    <RemoveModal
                        handleDelete={onDelete}
                        button={
                            <Button color="primary" variant="outlined">
                                {t('delete')}
                            </Button>
                        }
                        title={t('deleteProduct')}
                        text={t('deleteProductText')}
                    />
                )}
            </Fragment>,
            <Button key={'cancel'} onClick={() => navigate('/admin/products')} color="secondary">
                {t('cancel')}
            </Button>,
        ],
        [form, isFetching, t, id, onDelete, onSubmit, navigate],
    );

    const reversedActions = useMemo(() => [...actions].reverse(), [actions]);

    return (
        <Page
            onBack={() => navigate(-1)}
            title={product?.id ? t('updateProduct') : t('newProduct')}
            loading={isSaving}
            actions={reversedActions}
        >
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit((item) => onSubmit(item))}>
                    <Card variant="outlined">
                        <CardContent>
                            <SaveProductInputs />
                            {Object.keys(form.formState.errors).length > 0 && (
                                <Typography color="error">{t('errorIncorrectValues')}</Typography>
                            )}
                        </CardContent>
                        <CardActions sx={{ backgroundColor: 'background.default' }} children={actions} />
                    </Card>
                </form>
            </FormProvider>
            <BarcodeWarning
                showWarning={showWarning}
                setShowWarning={setShowWarning}
                onSubmit={() => {
                    setShowWarning(false);
                    form.handleSubmit((item) => onSubmit(item, true))();
                }}
            />
        </Page>
    );
};
