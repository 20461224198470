import { isAxiosError } from 'axios';
import { useCallback } from 'react';
import { ISaleItem, isBadRequest, isNotFound, useSelectedOrganisation, useShop } from '../../shared';
import { SaleError } from '../enums';
import { useCreateSaleItem, useCurrentSale, useUpdateSaleItem } from './sales.hook';
import { CustomerType } from '../../customer/enums/customer-type.enum';
import { ISupportCaseInfo } from '../../customer/models/support-case.model';

export function useOnScan(supportCaseInfo?: ISupportCaseInfo) {
    const {
        selectedProduct,
        selectedSaleItem,
        setProductNotFound,
        setSelectedProduct,
        setSelectedSaleItem,
        setShowWarningNoSupportCase,
        returnTabActive,
        customer,
        setCustomer,
    } = useShop();
    const { mutateAsync: createSaleItem } = useCreateSaleItem();
    const { mutateAsync: updateSaleItem } = useUpdateSaleItem();
    const { organisation } = useSelectedOrganisation();
    const { data: currentSale } = useCurrentSale(organisation?.id);

    const handleScanSupportCase = useCallback(
        (parsedCode: string) => {
            setShowWarningNoSupportCase(false);
            const cardCode = [parsedCode?.slice(0, 4), parsedCode?.slice(4)].join('-');
            setCustomer({ cardCode });
        },
        [setCustomer, setShowWarningNoSupportCase],
    );

    const scanNewSaleItem = useCallback(
        async (scanned: string, parsedCode: string, organisationId: string, supportCaseInfo?: ISupportCaseInfo) => {
            if (!returnTabActive) {
                const item = {
                    barcode: scanned,
                    organisationId: organisationId,
                    supportCaseId: supportCaseInfo?.supportCaseId || undefined,
                    cardCode: supportCaseInfo?.cardCode || undefined,
                    amountOfPeople: supportCaseInfo?.supportees.length || undefined,
                };
                try {
                    await createSaleItem(item);
                } catch (e) {
                    if (isNotFound(e)) {
                        //scanned code is unknown? => setProductNotFound (used to show warning)
                        setProductNotFound(scanned);
                    } else if (
                        isAxiosError(e) &&
                        isBadRequest(e) &&
                        [SaleError.AMOUNT_REQUIRED, SaleError.PRICE_REQUIRED].includes(e.response?.data.type)
                    ) {
                        //product found but expecting weight or product has no price => setSelectedProduct
                        setSelectedProduct({
                            barcode: parsedCode,
                            measurementUnit: e.response?.data.product.measurementUnit,
                            price: e.response?.data?.product?.hasPrice
                                ? e.response?.data?.product?.price * 1000
                                : undefined,
                            hasPrice: e.response?.data?.product?.hasPrice,
                        });
                    } else throw e;
                }
            }
        },
        [createSaleItem, returnTabActive, setProductNotFound, setSelectedProduct],
    );

    const updateSaleItemWeight = useCallback(
        async (scanned: string, prefix: string, selectedSaleItem: ISaleItem, amountOfPeople?: number) => {
            const saleItem = {
                saleItemId: selectedSaleItem.id,
                ...selectedSaleItem,
                amount: parseInt(scanned.replace(prefix, ''), 10),
                amountOfPeople: amountOfPeople || undefined,
            };

            await updateSaleItem({
                saleItemId: selectedSaleItem.id,
                saleItem,
            });
            setSelectedSaleItem(undefined);
        },
        [setSelectedSaleItem, updateSaleItem],
    );

    const createSaleItemWithWeight = useCallback(
        async (scanned: string, prefix: string, organisationId: string, supportCaseInfo?: ISupportCaseInfo) => {
            if (selectedProduct && !selectedProduct?.hasPrice) {
                setSelectedProduct({ ...selectedProduct, amount: parseInt(scanned.replace(prefix, ''), 10) });
            } else {
                const item = {
                    ...selectedProduct,
                    organisationId: organisationId,
                    supportCaseId: supportCaseInfo?.supportCaseId || undefined,
                    cardCode: supportCaseInfo?.cardCode || undefined,
                    amountOfPeople: supportCaseInfo?.supportees.length || undefined,
                    amount: parseInt(scanned.replace(prefix, ''), 10),
                    alteredProductPrice: selectedProduct?.hasPrice ? undefined : 0,
                };
                await createSaleItem(item);

                setSelectedProduct(undefined);
            }
        },
        [createSaleItem, selectedProduct, setSelectedProduct],
    );

    return useCallback(
        async (scanned: string) => {
            // scanning with azerty keyboard keeps -, scanning with qwerty keyboard removes -
            const parsedCode = scanned.replace(/-/g, '');
            const prefix = window.carity.environment.prefixScanWeight.replace(/-/g, '');

            if (!organisation) return;
            if (customer?.type !== CustomerType.SOLIDARITY && !currentSale && !supportCaseInfo) {
                //No supportCase selected yet? => scanned code tried as supportCase card code when it's not a solidarity sale
                handleScanSupportCase(parsedCode);
            } else {
                //SupportCase found? => scan a product
                if (!selectedProduct && !selectedSaleItem) {
                    //No product/sale item selected yet? => create a new sale item
                    await scanNewSaleItem(parsedCode, parsedCode, organisation.id, supportCaseInfo);
                } else {
                    if (selectedSaleItem) {
                        // Update weight from existing sale item
                        await updateSaleItemWeight(
                            parsedCode,
                            prefix,
                            selectedSaleItem,
                            supportCaseInfo?.supportees?.length || undefined,
                        );
                    } else if (parsedCode.startsWith(prefix) && (selectedProduct?.id || selectedProduct?.barcode)) {
                        // Create sale item with weight
                        await createSaleItemWithWeight(parsedCode, prefix, organisation.id, supportCaseInfo);
                    }
                }
            }
        },
        [
            organisation,
            customer,
            currentSale,
            supportCaseInfo,
            handleScanSupportCase,
            selectedProduct,
            selectedSaleItem,
            scanNewSaleItem,
            updateSaleItemWeight,
            createSaleItemWithWeight,
        ],
    );
}
