import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { boolean, date, object, string } from 'yup';
import { isMatch } from 'date-fns';
import { isDateValid } from '../../shared';

export function usePurchaseSchema() {
    const { t } = useTranslation();
    return useMemo(
        () =>
            object().shape({
                invoiceNumber: string().when('gift', {
                    is: false,
                    then: (schema) => schema.required(),
                    otherwise: (schema) => schema.optional(),
                }),
                supplierId: string()
                    .optional()
                    .test('required', t('requiredField'), (val) => !!val),
                date: date()
                    .typeError(t('requiredField'))
                    .test('hasDateValue', t('requiredField'), (value) => !!value)
                    .test('isValidDate', t('requiredField'), (value) => {
                        return value instanceof Date
                            ? isDateValid(value)
                            : isMatch(value as unknown as string, 'yyyy-MM-dd');
                    })
                    .required(),
                gift: boolean().required(),
            }),
        [t],
    );
}
