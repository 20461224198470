export default {
    customer: 'Klant',
    customers: 'Klanten',
    newCustomer: 'Nieuwe klant',
    updateCustomer: 'Wijzig klant',
    deleteCustomer: 'Verwijder klant',
    deleteCustomerText: 'Bent u zeker dat u deze klant wilt verwijderen?',
    customerTypes: 'Klantentypes',
    customerType: 'Klanttype',
    socialCustomer: 'Sociale klant',
    solidarityCustomer: 'Solidaire klant',
    SOCIAL: 'Sociale klant',
    SOLIDARITY: 'Solidaire klant',
    PROJECT: 'Project (Aankoopprijs)',
    customerInformation: 'Klantinformatie',
    downloadCard: 'Download klantenkaart',
    customerWarning: 'Opslaan niet gelukt',
    customerWarningText:
        'Er liep iets mis bij het opslaan van de klant, probeer opnieuw.\n\nIndien deze foutmelding zich opnieuw voordoet, contacteer de verantwoordelijke.',
};
