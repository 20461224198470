import React, { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { IParams, Page, RemoveModal, StatusCode, useSelectedOrganisation } from '../../../shared';
import { useCustomer, useDeleteCustomer, useSaveCustomer } from '../../hooks/customers.hook';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ICustomerForm } from '../../models/customer.model';
import { useCustomerSchema } from '../../validators/customer.validator';
import { AxiosError, isAxiosError } from 'axios';
import { Button, Card, CardActions, CardContent } from '@mui/material';
import { CustomerWarning } from '../../components/customers/customer-warning.component';
import { SaveCustomerInputs } from '../../components/customers/save-customer-inputs.component';

export const CustomerEditPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams<keyof IParams>() as IParams;
    const { organisation } = useSelectedOrganisation();
    const [showWarning, setShowWarning] = useState(false);

    const { data: customer, isFetching } = useCustomer(id);
    const { mutateAsync: saveCustomer, isPending: isSaving } = useSaveCustomer();
    const { mutateAsync: deleteCustomer } = useDeleteCustomer();

    const form = useForm<ICustomerForm>({
        mode: 'onSubmit',
        resolver: yupResolver(useCustomerSchema()),
    });

    useEffect(() => {
        if (customer) form.reset(customer);
        else form.reset();
    }, [customer, form]);

    const onSubmit = useCallback(
        async (item: ICustomerForm) => {
            try {
                if (organisation?.id) {
                    const result = await saveCustomer({
                        id,
                        item: { ...item, organisationId: organisation.id },
                    });
                    navigate(`/customer/customers/${result.id}`);
                }
            } catch (e) {
                if (isAxiosError(e)) {
                    if ((e as AxiosError)?.response?.status === StatusCode.CONFLICT) setShowWarning(true);
                    else throw e;
                }
            }
        },
        [id, navigate, organisation, saveCustomer],
    );

    const onDelete = useCallback(async () => {
        await deleteCustomer(id);
        navigate('/customer/customers');
    }, [deleteCustomer, navigate, id]);

    const actions = useMemo(
        () => [
            <Button
                key={'submit'}
                variant="contained"
                color="primary"
                onClick={form.handleSubmit((item) => onSubmit(item))}
                disabled={isFetching}
            >
                {t('save')}
            </Button>,
            <Fragment key="delete">
                {id && (
                    <RemoveModal
                        handleDelete={onDelete}
                        button={
                            <Button color="primary" variant="outlined">
                                {t('delete')}
                            </Button>
                        }
                        title={t('deleteCustomer')}
                        text={t('deleteCustomerText')}
                    />
                )}
            </Fragment>,
            <Button key={'cancel'} onClick={() => navigate('/customer/customers')} color="secondary">
                {t('cancel')}
            </Button>,
        ],
        [form, isFetching, t, id, onDelete, onSubmit, navigate],
    );

    const reversedActions = useMemo(() => [...actions].reverse(), [actions]);

    return (
        <Page
            onBack={() => navigate(-1)}
            title={customer?.id ? t('updateCustomer') : t('newCustomer')}
            loading={isSaving}
            actions={reversedActions}
        >
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit((item) => onSubmit(item))}>
                    <Card variant="outlined">
                        <CardContent>
                            <SaveCustomerInputs />
                        </CardContent>
                        <CardActions sx={{ backgroundColor: 'background.default' }} children={actions} />
                    </Card>
                </form>
            </FormProvider>

            <CustomerWarning showWarning={showWarning} setShowWarning={setShowWarning} />
        </Page>
    );
};
