import shared from './shared.nl';
import auth from './auth.nl';
import main from './main.nl';
import admin from './admin.nl';
import shop from './shop.nl';
import stock from './stock.nl';
import history from './history.nl';
import report from './report.nl';
import customer from './customer.nl';

export const translationsNL = {
    translation: {
        ...shared,
        ...auth,
        ...main,
        ...admin,
        ...shop,
        ...stock,
        ...history,
        ...report,
        ...customer,
    },
};
