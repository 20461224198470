import { QueryKeys, useDelete, useItem, UseItemParams, useList, UseListParams, useSave } from '../../shared';
import { customersClient } from '../clients/customers.client';
import { ICustomer, ICustomerQuery } from '../models/customer.model';

const client = customersClient;
const listKey = QueryKeys.CUSTOMERS;
const itemKey = QueryKeys.CUSTOMER;

export function useCustomerList(...args: UseListParams<ICustomer, ICustomerQuery>) {
    return useList(client, listKey, ...args);
}

export function useCustomer(...args: UseItemParams<ICustomer>) {
    return useItem(client, itemKey, ...args);
}

export function useSaveCustomer() {
    return useSave(client, listKey, itemKey);
}

export function useDeleteCustomer() {
    return useDelete(client, listKey);
}
