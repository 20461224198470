import { FilterType, IReportFilter, IRunReportFilter } from '../models';

export function allRequiredFiltersFilledInCheck(
    availableFilters: IReportFilter[],
    filters: IRunReportFilter[],
): boolean {
    return availableFilters
        .filter(({ required }) => required)
        .every((requiredFilter) =>
            filters.some((filter) => {
                if (requiredFilter.type === filter.type && requiredFilter.column === filter.column) {
                    if (filter.type === FilterType.SELECT) return filter.values.length > 0;
                    if (filter.type === FilterType.SINGLE_SELECT) return filter.value.length > 0;
                    if (filter.type === FilterType.DATE) return filter.start && filter.end;
                    if (filter.type === FilterType.NUMBER) return filter.min !== undefined && filter.max !== undefined;
                }
            }),
        );
}
