import { Grid } from '@mui/material';
import React, { FC } from 'react';
import { ISale, useShop } from '../../../shared';
import { BudgetExceeded } from '../budget-exceeded/budget-exceeded.component';
import { CurrentSaleShoppingList } from '../current-sale-shopping-list/current-sale-shopping-list.component';
import { Products } from '../products/products.component';
import { QuotumExceeded } from '../quotum-exceeded/quotum-exceeded.component';
import { ShopActions } from '../shop-actions/shop-actions.component';
import { WarningProductNotFound } from '../warning-product-not-found/warning-product-not-found.component';
import { ISupportCaseInfo } from '../../../customer/models/support-case.model';
import { SupportCaseInfoComponent } from '../../../customer/components/support-case-info/support-case-info.component';

interface Props {
    supportCase?: ISupportCaseInfo;
    currentSale?: ISale;
}

export const Register: FC<Props> = ({ supportCase, currentSale }) => {
    const { productNotFound, setProductNotFound } = useShop();

    return (
        <>
            <Grid direction="column" flexWrap="nowrap" container sx={{ height: 'calc(100vh - 103px)' }}>
                <Grid
                    item
                    container
                    flexGrow={1}
                    flexShrink={1}
                    columnSpacing={{ xs: 0, md: 2 }}
                    sx={{ pb: '0 !important' }}
                >
                    <Grid item xs={12} sm={6}>
                        <CurrentSaleShoppingList currentSale={currentSale} supportCase={supportCase} />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        container
                        flexWrap="nowrap"
                        direction="column"
                        sx={{ height: 'calc(100vh - 163px)' }}
                    >
                        <Grid item mb={2}>
                            <SupportCaseInfoComponent supportCase={supportCase} sale={currentSale} />
                        </Grid>
                        <Grid item flexGrow={1} flexShrink={1} sx={{ overflow: 'hidden' }}>
                            <Products />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <ShopActions currentSale={currentSale} supportCase={supportCase} />
                </Grid>
            </Grid>
            <WarningProductNotFound
                show={!!productNotFound}
                onClose={() => setProductNotFound(undefined)}
                barcode={productNotFound}
            />
            <QuotumExceeded />
            <BudgetExceeded />
        </>
    );
};
