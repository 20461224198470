import { BaseClient, shopApi } from '../../shared';
import { ICustomer, ICustomerQuery, ISaveCustomer } from '../models/customer.model';

class CustomersClient extends BaseClient<ICustomer, ISaveCustomer, ICustomerQuery> {
    constructor() {
        super(shopApi, '/customers');
    }
}

export const customersClient = new CustomersClient();
