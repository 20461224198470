import { Button, Dialog, DialogActions, DialogContent, Stack } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogTitleWithClose, formatPrice } from '../../../shared';

interface Props {
    total: number;
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
}

export const CompletePurchaseActions: FC<Props> = ({ total, open, onClose, onConfirm }) => {
    const { t } = useTranslation();
    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitleWithClose onClose={onClose}>{t('confirmCompletePurchaseTitle')}</DialogTitleWithClose>
            <DialogContent>{t('confirmCompletePurchaseText', { total: formatPrice(total) })}</DialogContent>
            <DialogActions>
                <Stack direction="row" spacing={2}>
                    <Button variant="contained" onClick={onConfirm}>
                        {t('confirm')}
                    </Button>
                    <Button variant="outlined" onClick={onClose}>
                        {t('shared.cancel')}
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};
