import { Button, Grid } from '@mui/material';
import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ISale, RemoveModal, SaleStatus, useShop } from '../../../shared';
import { useCancelCurrentSale, useUpdateSale } from '../../hooks';
import { CompleteCurrentSale } from '../complete-current-sale/complete-current-sale.component';
import { ISupportCaseInfo } from '../../../customer/models/support-case.model';

interface Props {
    supportCase?: ISupportCaseInfo;
    currentSale?: ISale | void;
}

export const ShopActions: FC<Props> = ({ supportCase, currentSale }) => {
    const { t } = useTranslation();
    const [showCompleteSale, setShowCompleteSale] = useState(false);
    const { setCustomer } = useShop();

    const { mutateAsync: cancelCurrentSale } = useCancelCurrentSale();
    const { mutateAsync: updateSale } = useUpdateSale();

    const onCancel = useCallback(async () => {
        if (currentSale) await cancelCurrentSale(currentSale.id);
        setCustomer(undefined);
    }, [cancelCurrentSale, currentSale, setCustomer]);

    const onHold = useCallback(async () => {
        if (currentSale) await updateSale({ id: currentSale.id, item: { status: SaleStatus.ON_HOLD } });
        setCustomer(undefined);
    }, [currentSale, setCustomer, updateSale]);

    const onCompleteSale = () => {
        setCustomer(undefined);
    };

    return (
        <>
            <Grid container justifyContent="space-between" spacing={1.5} sx={{ button: { minWidth: '100%', mt: 1.5 } }}>
                <Grid item xs={6}>
                    <Button variant="contained" onClick={() => setShowCompleteSale(true)} disabled={!currentSale}>
                        {t('checkout')}
                    </Button>
                </Grid>
                <Grid item xs={3}>
                    <Button variant="outlined" onClick={onHold} disabled={!currentSale}>
                        {t('onHold')}
                    </Button>
                </Grid>
                <Grid item xs={3}>
                    <RemoveModal
                        handleDelete={() => onCancel()}
                        button={<Button variant="outlined">{t('emptyShoppingCart')}</Button>}
                        title={t('emptyShoppingCart')}
                        text={t('emptyShoppingCartWarning')}
                    />
                </Grid>
            </Grid>

            {currentSale && (
                <CompleteCurrentSale
                    supportCase={supportCase}
                    open={showCompleteSale}
                    onClose={() => setShowCompleteSale(false)}
                    currentSale={currentSale}
                    onCompleteSale={() => onCompleteSale()}
                />
            )}
        </>
    );
};
