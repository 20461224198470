import { IRouteConfig, IRouteConfigWithComponent, PermissionKeys } from '../shared';
import { CustomerPage } from './pages/customer.page';
import { CustomerEditPage } from './pages/customers/customer-edit.page';
import { CustomerDetailPage } from './pages/customers/customer-detail.page';
import { CustomersPage } from './pages/customers/customers.page';

export const customerRoutes: IRouteConfig[] = [
    { path: '/customer', redirect: '/customer/customers', requiredPermissions: [PermissionKeys.CUSTOMERS_READ] },
    { path: '/customer/*', component: CustomerPage },
];

export const customerSubRoutes: IRouteConfigWithComponent[] = [
    { path: '/customers', component: CustomersPage, requiredPermissions: [PermissionKeys.CUSTOMERS_READ] },
    {
        path: '/customers/new',
        component: CustomerEditPage,
        requiredPermissions: [PermissionKeys.CUSTOMERS_WRITE],
    },
    {
        path: '/customers/:id',
        component: CustomerDetailPage,
        requiredPermissions: [PermissionKeys.CUSTOMERS_READ],
    },
    {
        path: '/customers/:id/edit',
        component: CustomerEditPage,
        requiredPermissions: [PermissionKeys.CUSTOMERS_WRITE],
    },
];
