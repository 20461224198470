import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
    FormGrid,
    IParams,
    LabelValue,
    Page,
    PermissionKeys,
    RemoveModal,
    useHasPermission,
    Widget,
} from '../../../shared';
import { useCustomer, useDeleteCustomer } from '../../hooks/customers.hook';
import { Button } from '@mui/material';
import { ListAltTwoTone, RecentActorsTwoTone } from '@mui/icons-material';
import { DownloadCard } from '../../components/customers/download-card.component';

export const CustomerDetailPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { hasPermission } = useHasPermission();
    const hasWritePermission = hasPermission(PermissionKeys.CUSTOMERS_WRITE);

    const { id } = useParams<keyof IParams>() as IParams;
    const { data: customer, isPending } = useCustomer(id);
    const { mutateAsync: deleteCustomer } = useDeleteCustomer();

    const onDelete = useCallback(async () => {
        await deleteCustomer(id);
        navigate('/customer/customers');
    }, [deleteCustomer, navigate, id]);

    return (
        <Page
            onBack={() => navigate('/customer/customers')}
            title={customer?.name}
            loading={isPending}
            actions={
                hasWritePermission && [
                    <RemoveModal
                        handleDelete={onDelete}
                        button={
                            <Button color="primary" variant="outlined">
                                {t('delete')}
                            </Button>
                        }
                        title={t('deleteCustomer')}
                        text={t('deleteCustomerText')}
                    />,
                    <Button onClick={() => navigate(`./edit`)} variant="contained">
                        {t('edit')}
                    </Button>,
                ]
            }
        >
            {customer && (
                <FormGrid xs={12} sm={6} containerProps={{ pr: 0 }}>
                    <Widget title={t('customerInformation')} icon={<ListAltTwoTone color="primary" />}>
                        <FormGrid xs={12} sm={6}>
                            <LabelValue label={t('name')} value={customer.name} />
                            <LabelValue label={t('type')} value={t(customer.type)} />
                        </FormGrid>
                    </Widget>

                    <Widget
                        title={t('customerCard')}
                        icon={<RecentActorsTwoTone color="primary" />}
                        actions={customer?.card?.id && <DownloadCard cardId={customer.card.id} />}
                    >
                        <LabelValue label={t('card')} value={customer.card.code} />
                    </Widget>
                </FormGrid>
            )}
        </Page>
    );
};
