import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogTitleWithClose, useShop } from '../../../shared';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { CustomerType } from '../../../customer/enums/customer-type.enum';

interface Props {
    open: boolean;
    handleClose: () => void;
}

export const SearchSolidarityCustomerModal: FC<Props> = ({ open, handleClose }) => {
    const { t } = useTranslation();
    const { setCustomer } = useShop();

    return (
        <Dialog open={open} fullWidth={true} maxWidth="lg" disableRestoreFocus>
            <DialogTitleWithClose onClose={handleClose}>{t('searchSolidarityCustomer')}</DialogTitleWithClose>
            <DialogContent>
                <Button
                    variant="outlined"
                    onClick={() => setCustomer({ type: CustomerType.SOLIDARITY })}
                    size="large"
                    sx={{ width: '100%' }}
                >
                    {t('solidaritySale')}
                </Button>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" variant="contained">
                    {t('cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
