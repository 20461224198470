import { createContext, FC, ReactNode, useContext, useState } from 'react';
import { IBudgetRuleExceeded, IQuotumRuleExceeded, ISaleItem, MeasurementUnit } from '../index';
import { CustomerType } from '../../customer/enums/customer-type.enum';

export type ICustomerContext = {
    id?: string;
    cardCode?: string;
    type?: CustomerType;
};

export type SelectedProductType = {
    id?: string;
    name?: string;
    barcode?: string;
    measurementUnit: MeasurementUnit;
    price?: number | null;
    solidarityPrice?: number | null;
    hasPrice: boolean;
    amount?: number;
};

interface IShopContext {
    selectedProduct?: SelectedProductType;
    setSelectedProduct: (product: SelectedProductType | undefined) => void;
    selectedSaleItem?: ISaleItem;
    setSelectedSaleItem: (saleItem: ISaleItem | undefined) => void;
    customer?: ICustomerContext;
    setCustomer: (customer: ICustomerContext | undefined) => void;
    showWarningNoSupportCase?: boolean;
    setShowWarningNoSupportCase: (showWarningNoSupportCase: boolean | false) => void;
    productNotFound?: string | undefined;
    setProductNotFound: (productNotFound: string | undefined) => void;
    quotumRuleExceeded?: IQuotumRuleExceeded;
    setQuotumRuleExceeded: (rule: IQuotumRuleExceeded | undefined) => void;
    budgetExceeded?: IBudgetRuleExceeded;
    setBudgetExceeded: (error: IBudgetRuleExceeded | undefined) => void;
    returnTabActive?: boolean;
    setReturnTabActive: (returnTabActive: boolean) => void;
}

const ShopContext = createContext<IShopContext>({
    setSelectedProduct: () => {},
    setSelectedSaleItem: () => {},
    setCustomer: () => {},
    setShowWarningNoSupportCase: () => {},
    setProductNotFound: () => {},
    setQuotumRuleExceeded: () => {},
    setBudgetExceeded: () => {},
    setReturnTabActive: () => {},
});

type Props = {
    children?: ReactNode;
};

export const ShopProvider: FC<Props> = ({ children }) => {
    const [selectedProduct, setSelectedProduct] = useState<SelectedProductType>();
    const [selectedSaleItem, setSelectedSaleItem] = useState<ISaleItem>();
    const [customer, setCustomer] = useState<ICustomerContext | undefined>();
    const [showWarningNoSupportCase, setShowWarningNoSupportCase] = useState(false);
    const [productNotFound, setProductNotFound] = useState<string | undefined>(undefined);
    const [quotumRuleExceeded, setQuotumRuleExceeded] = useState<IQuotumRuleExceeded>();
    const [budgetExceeded, setBudgetExceeded] = useState<IBudgetRuleExceeded>();
    const [returnTabActive, setReturnTabActive] = useState<boolean>(false);

    return (
        <ShopContext.Provider
            value={{
                selectedProduct,
                setSelectedProduct,
                selectedSaleItem,
                setSelectedSaleItem,
                customer,
                setCustomer,
                showWarningNoSupportCase,
                setShowWarningNoSupportCase,
                productNotFound,
                setProductNotFound,
                quotumRuleExceeded,
                setQuotumRuleExceeded,
                budgetExceeded,
                setBudgetExceeded,
                returnTabActive,
                setReturnTabActive,
            }}
        >
            {children}
        </ShopContext.Provider>
    );
};

export const useShop = (): IShopContext => useContext(ShopContext);
