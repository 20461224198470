import { useMemo } from 'react';
import { IBudget } from '../../shared';
import { ISupportCaseInfo } from '../../customer/models/support-case.model';

export function useTotalBudget(supportCase: ISupportCaseInfo | undefined, budget: IBudget | undefined) {
    return useMemo(() => {
        if (supportCase && budget && budget?.rules?.length > 0) {
            const rule =
                budget.rules
                    .sort((a, b) => b.amountOfPeople - a.amountOfPeople)
                    .find((rule) => rule.amountOfPeople <= supportCase.supportees.length) ||
                budget.rules.sort((a, b) => a.amountOfPeople - b.amountOfPeople)[0];

            return rule.amount;
        }
    }, [budget, supportCase]);
}
