export enum QueryKeys {
    AUTH_USER = 'auth-user',
    AUTH_PROVIDERS = 'auth-provider',
    USER_ROLES = 'user-roles',
    SUPPORT_CASES = 'support-cases',
    SUPPORT_CASE_INFO = 'support-case-info',
    ORGANISATIONS = 'organisations',
    PRODUCT = 'product',
    PRODUCTS = 'products',
    CATEGORY = 'category',
    CATEGORIES = 'categories',
    SHOP_CATEGORY = 'shop-category',
    SHOP_CATEGORIES = 'shop-categories',
    CURRENT_SALE = 'current-sale',
    SUPPLIER = 'supplier',
    SUPPLIERS = 'suppliers',
    PURCHASE = 'purchase',
    PURCHASES = 'purchases',
    SETTINGS = 'settings',
    REGISTER_SESSION = 'register-session',
    REGISTER_SESSIONS = 'register-sessions',
    SALES = 'sales',
    SALE_QUOTUM = 'sale-quotum',
    SALE_QUOTA = 'sale-quota',
    STOCK_MOVEMENT = 'stock-movement',
    STOCK_MOVEMENTS = 'stock-movements',
    BUDGET = 'budget',
    SUPPORT_TYPES = 'support-types',
    DASHBOARDS = 'dashboards',
    REPORT = 'report',
    REPORT_RESULT = 'report-result',
    CUSTOMERS = 'customers',
    CUSTOMER = 'customer',
}
