import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogContent, InputAdornment, Stack } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSettings } from '../../../admin/hooks';
import {
    ControlledAutocomplete,
    ControlledNumberInput,
    formatPrice,
    FormGrid,
    LabelValue,
    PaymentMethod,
    useSelectedOrganisation,
} from '../../../shared';
import { ICreditPaymentForm, ISavePayment } from '../../models';
import { validateCurrentAmount } from '../../utils';
import { useCreditSchema } from '../../validators';
import { InputNumpad } from '../input-numpad/input-numpad.component';
import { ISupportTypeInfo } from '../../../customer/models/support-type.model';

interface Props {
    supportTypesInfo: ISupportTypeInfo[];
    creditPayment?: ISavePayment | null;
    setCreditPayment: (payment: ISavePayment | null) => void;
    handleCancel: () => void;
    totalPrice: number;
}
export const CreditPayment: FC<Props> = ({
    creditPayment,
    setCreditPayment,
    handleCancel,
    supportTypesInfo,
    totalPrice,
}) => {
    const { t } = useTranslation();
    const { organisation } = useSelectedOrganisation();
    const { data: settings } = useSettings(organisation?.id);
    const [amount, setAmount] = useState('');

    const form = useForm<ICreditPaymentForm>({ resolver: yupResolver(useCreditSchema(totalPrice)) });

    useEffect(() => {
        form.setValue('amount', Number(amount));
    }, [amount, form]);

    useEffect(() => {
        if (creditPayment) {
            form.reset({
                supportTypeInfo: supportTypesInfo.find(
                    (info) =>
                        info.supportTypeId === creditPayment.supportTypeId &&
                        info.supportPeriodId === creditPayment.supportPeriodId,
                ),
                amount: creditPayment.paidAmount,
            });
        } else {
            form.setValue('supportTypeInfo', supportTypesInfo[0]);
        }
    }, [form, supportTypesInfo, creditPayment]);

    const watchSupportTypeInfo = form.watch('supportTypeInfo');
    const watchAmount = form.watch('amount');

    const onHandlePayments = (credit: ICreditPaymentForm) => {
        setCreditPayment(
            credit.amount
                ? {
                      paidAmount: Number(credit.amount),
                      method: PaymentMethod.CREDIT,
                      receivedAmount: Number(credit.amount),
                      returnedAmount: 0,
                      supportTypeId: credit.supportTypeInfo.supportTypeId,
                      supportPeriodId: credit.supportTypeInfo.supportPeriodId,
                      referralOrganisationName: credit.supportTypeInfo.referralOrganisationName,
                  }
                : null,
        );
    };
    return (
        <DialogContent>
            <FormGrid xs={6}>
                <Stack direction="column" justifyContent="space-between" spacing={2}>
                    <FormProvider {...form}>
                        <form>
                            <ControlledAutocomplete
                                name="supportTypeInfo"
                                defaultValue={null}
                                options={supportTypesInfo}
                                getOptionLabel={(value) =>
                                    `${value.supportType?.name} ${formatPrice((value?.credit || 0) - (value?.spendCredit || 0))} (${t(value.frequency as string)})`
                                }
                                isOptionEqualToValue={(option, value) =>
                                    option.supportTypeId === value.supportTypeId &&
                                    option.supportPeriodId === value.supportPeriodId
                                }
                            />
                            <LabelValue label={t('totalPrice')} value={formatPrice(totalPrice)} />
                            <LabelValue
                                label={t('availableCredit')}
                                value={formatPrice(
                                    (watchSupportTypeInfo?.credit || 0) - (watchSupportTypeInfo?.spendCredit || 0),
                                )}
                            />
                            <ControlledNumberInput
                                name="amount"
                                label={t('paidCredit')}
                                slotProps={{
                                    input: { startAdornment: <InputAdornment position="start" children={'€'} /> },
                                    htmlInput: { readOnly: true },
                                }}
                                decimalScale={2}
                                focused
                            />
                            <LabelValue
                                label={t('remainingCredit')}
                                value={
                                    watchSupportTypeInfo?.credit
                                        ? formatPrice(
                                              watchSupportTypeInfo.credit -
                                                  (watchSupportTypeInfo?.spendCredit || 0) -
                                                  Number(watchAmount),
                                          )
                                        : undefined
                                }
                            />
                        </form>
                    </FormProvider>
                </Stack>
                <InputNumpad
                    value={amount}
                    onChange={setAmount}
                    disable={validateCurrentAmount(amount, undefined, true)}
                    showNumpad={settings?.showNumpad}
                />
            </FormGrid>

            <Stack direction="row" mt={3} spacing={2} pr={4}>
                <Button variant="outlined" onClick={handleCancel} fullWidth>
                    {t('cancel')}
                </Button>
                <Button variant="contained" onClick={form.handleSubmit(onHandlePayments)} fullWidth>
                    {t('next')}
                </Button>
            </Stack>
        </DialogContent>
    );
};
