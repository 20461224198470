import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ControlledInput, ControlledSelect, FormGrid } from '../../../shared';
import { CustomerType } from '../../enums/customer-type.enum';
import { MenuItem } from '@mui/material';

export const SaveCustomerInputs: FC = () => {
    const { t } = useTranslation();

    return (
        <FormGrid sm={12} md={6}>
            <ControlledInput name="name" label={t('name')} required />
            <ControlledSelect label={t('type')} name={`type`} required>
                <MenuItem value={CustomerType.SOLIDARITY} key={CustomerType.SOLIDARITY}>
                    {t(CustomerType.SOLIDARITY)}
                </MenuItem>
                <MenuItem value={CustomerType.PROJECT} key={CustomerType.PROJECT}>
                    {t(CustomerType.PROJECT)}
                </MenuItem>
            </ControlledSelect>
        </FormGrid>
    );
};
