import React, { FC, useCallback } from 'react';
import { Button, Dialog, DialogActions, DialogContent, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DialogTitleWithClose } from '../../../shared';

interface Props {
    showWarning: boolean;
    setShowWarning: (showWarning: boolean) => void;
}

export const CustomerWarning: FC<Props> = ({ showWarning, setShowWarning }) => {
    const { t } = useTranslation();
    const close = useCallback(() => setShowWarning(false), [setShowWarning]);

    return (
        <Dialog open={showWarning} onClose={close}>
            <DialogTitleWithClose onClose={close}>{t('customerWarning')}</DialogTitleWithClose>
            <DialogContent sx={{ whiteSpace: 'pre-wrap' }}>{t('customerWarningText')}</DialogContent>
            <DialogActions>
                <Stack direction="row" spacing={2}>
                    <Button variant="contained" onClick={close}>
                        {t('ok')}
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};
