import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { QueryKeys } from '../enums';
import { IOrganisation, ListModel, OrganisationsListQueryParam } from '../models';
import { supportApi } from '../clients';

const getOrganisations = async (
    params: OrganisationsListQueryParam,
    onGetErrorMessage?: string,
): Promise<ListModel<IOrganisation>> => {
    const { data } = await supportApi.get('/organisations', { params, errorMessage: onGetErrorMessage });
    return data;
};

export const useGetOrganisations = (params: OrganisationsListQueryParam) => {
    const { t } = useTranslation();
    return useQuery<ListModel<IOrganisation>, AxiosError>({
        queryKey: [QueryKeys.ORGANISATIONS, params],
        queryFn: () => getOrganisations(params, t('getOrganisationsError')),
        enabled: params.pageSize == undefined || params.pageSize > 0,
    });
};
